@import "../../styles.css";

.main {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    align-items: center;
}

.header {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
}

.header p {
    font-size: 0.75rem;
}

.dropdownImage {
    width: 0.75rem;
    height: 0.75rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 0.25rem;
    margin-left: 0.4rem;
}

.header:active {
    color: gray;
}

.children {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 2.1rem;
    z-index: 100;
    right: 0;
    background-color: var(--panel-semi-dark-background);
}
