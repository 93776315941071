.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20rem;
    font-size: 0.9rem;
}

.content label {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.content input {
    background-color: var(--text-light-color);
    color: black;
    border: none;
    padding-left: 0.5rem;
    width: 100%;
    height: 2rem;
    box-sizing: border-box;
}

.content input:focus {
    outline: none;
}

.content p {
    margin-block-end: 0;
    margin-block-start: 0;
}

.resendCode {
    align-self: end;
    margin-top: 0.25rem;
}

.tips {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.needHelp {
    width: 100%;
}
