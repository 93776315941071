@import "../../styles.css";

.main {
    padding-left: 0.7rem;
    display: flex;
    flex-direction: row;
    padding-right: 1.2rem;
    height: 2rem;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
}

.main p {
    font-size: 0.75rem;
}

.iconImage {
    width: 1rem;
    height: 1rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 0.25rem;
}

.main:hover {
    background-color: dimgray;
}
