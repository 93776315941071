.main {
    padding-left: 0.5rem;
}

.container input {
    background-color: var(--text-light-color);
    color: black;
    border: none;
    padding-left: 0.5rem;
    height: 2rem;
    box-sizing: border-box;
    width: 65%;
}

.box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container input:focus {
    outline: none;
}

.actions {
    margin-top: 0.5rem;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.responseText {
    width: 34rem;
    height: 15rem;
    margin-top: 1.5rem;
    font-size: 0.9rem;
}

.responseText p {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.helpText {
    width: 34rem;
    height: 15rem;
    margin-top: 1.5rem;
    font-size: 0.9rem;
}

.helpText p {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.needHelp {
    width: 18rem;
}

a {
    color: rgb(70, 158, 246);
    text-decoration: none;
}