.main {
  width: 100vw;
  height: 3rem;
  padding-left: 0.5rem;
  background-color: #1d1d1d;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
}

.main p {
  color: white;
  margin: 0;
}

.logoContainer {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.logo {
  width: 1.25rem;
  height: 1.25rem;
  object-fit: contain;
  margin-right: 0.5rem;
}

.migrateButton {
  margin-right: 1rem;
}

.quickTipsButton {
  margin-right: 1rem;
}

.logoutContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 0.5rem;
}

.passwordManager {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
}

.passwordManager > * {
  margin-bottom: 1rem;
}

.passwordManager label {
  margin-bottom: 0.5rem;
}

.actions {
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}