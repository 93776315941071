.primaryBtn {
    outline: 0;
	border: 2px solid var(--btn-primary-color);
	border-radius: 1.25rem;
	background-color: transparent;
	color: var(--text-light-color);
	padding: 0.5rem 1rem;
	min-width: 5rem;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    cursor: pointer;
}

.primaryBtn.negative {
    border-color: var(--btn-negative-color);
}

.primaryBtn.positive {
    border-color: var(--btn-positive-color);
}

.primaryBtn:hover:enabled {
    background-color: var(--btn-primary-color);
}

.primaryBtn:hover:enabled.negative {
    background-color: var(--btn-negative-color);
}

.primaryBtn:hover:enabled.positive {
    background-color: var(--btn-positive-color);
}

.primaryBtn:disabled {
    opacity: 0.3;
    pointer-events: none;
}

.primaryBtn:active {
    background-color: rgb(30, 35, 41) !important;
}

.primaryBtn:focus {
    outline: 0;
}
