.activateProductContent {
    display: flex;
    flex-direction: column;
}

.activateProductContent > input {
    width: calc(100% - 0.5rem);
}

.activateProductActions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.activateProductActions button:nth-child(2) {
    margin-left: 12px;
}

.purchaseLicense {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    margin-top: 0;
}

.activationLabel {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    margin-top: 0;
}

.purchaseLicense a {
    color: var(--btn-primary-color);
}

.mode {
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    margin-top: 1rem;
}

.mode > label > input {
    margin: 0 0.25rem 0 0;
    cursor: pointer;
}

.mode label {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.selecteLicense {
    margin: 0.5rem 0 0 1rem;
}

.selecteLicense select {
    margin-left: 0.5rem;
    padding: 0.125rem;
    font-size: 0.75rem;
}

.instance {
    margin-bottom: 0.75rem;
}

.mode .disabledLabel {
    opacity: 0.3;
    cursor: auto;
}

.mode .disabledLabel input {
    cursor: auto;
}