.redeemContainer {
    display: flex;
    justify-content: flex-end;
    margin: 0 0.5rem 0.5rem 0;
}

.product {
    width: 19rem;
    padding-bottom: 2rem;
    background: linear-gradient(to bottom,#212121 0%, #4f4f4f 100%);
    border: 1px solid #827d80;
    border-radius: 4px;
    max-height: 33rem;
    display: flex;
    flex-direction: column;
}

.product img {
    display: block;
    width: 5rem;
    margin: 1rem auto;
}

.title {
    margin-bottom: 0.25rem;
    display: flex;
    justify-content: center;
}

.productStatus {
    text-align: center;
    font-size: 1.2rem;
}

.licenseContainer {
    margin: 1rem 1.5rem 0;
    padding: 0.8rem 0;
    background-color: #293039;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.serialNo {
    font-size: 0.875rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
    user-select: all;
}

.licenseValidity {
    text-align: center;
    font-size: 0.75rem;
    margin-bottom: 0.375rem;
}

.activeDevice {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.deviceName {
    font-size: 0.875rem;
    margin-bottom: 0.125rem;
    font-weight: bold;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lastActive {
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
}

.signOut {
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    flex: 1;
    overflow-y: auto;
}