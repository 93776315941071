.main {
    position: relative;
    display: flex;
    align-items: center;
}

.form-field {
    background-color: var(--text-light-color);
    color: black;
    border: none;
    padding-left: 0.5rem;
}

.form-field:focus {
   outline: none;
}  