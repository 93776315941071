.actions {
    width: 100%;
    position: relative;
}

.quickStartDialog :global(.MuiDialogActions-root) {
    width: calc(100% - 3rem);
}

.quickStartContainer {
    height: 28rem;
    width: 50rem;
}

.quickStartDialog :global(.MuiDialog-paperWidthSm) {
    align-items: center;
    max-width: 60vw;
}

.quickStartContainer > div {
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.quickStartContainer iframe {
    height: 100%;
    width: 100%;
}

.dontShow {
    float: left;
    padding-left: 0.5rem;
}

.dontShow label {
    display: flex;
    margin: 0;
    color: var(--text-light-color);
}

.dontShow input {
    width: 1rem;
    margin: 0 0.375rem 0 0;
}

.closeButton {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    min-width: 7rem;
}
