.helpDialog :global(.MuiDialogActions-root) {
    width: calc(100% - 3rem);
    padding-bottom: 1rem;
}

.helpContainer {
    height: 18rem;
    width: 36rem;
}

:global(.helpContainer-widthMd) {
    width: 20rem;
}

:global(.helpContainer-widthLg) {
    width: 36rem;
}

.helpDialog :global(.MuiDialog-paperWidthSm) {
    max-width: 60vw;
}

.helpContainer > div {
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.helpContainer iframe {
    height: 100%;
    width: 100%;
}

.actions {
    width: 100%;
    text-align: center;
}

.closeButton {
    min-width: 7rem;
}
