.actions {
    width: 100%;
    position: relative;
}

.quickTipsDialog :global(.MuiDialogActions-root) {
    width: calc(100% - 3rem);
}

.quickTipsContainer {
    height: 28rem;
    width: 50rem;
}

.quickTipsDialog :global(.MuiDialog-paperWidthSm) {
    align-items: center;
    max-width: 60vw;
}

.quickTipsContainer > div {
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.quickTipsContainer iframe {
    height: 100%;
    width: 100%;
}

.needHelp {
    float: left;
    padding-left: 0.5rem;
}

.closeButton {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    min-width: 7rem;
}