.message p {
    color: var(--text-light-color) !important;
    font-size: 1rem;
}

.actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}