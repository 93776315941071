.App {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

input:focus, select:focus, button:focus {
    outline: none;
}

.MuiDialog-paper.MuiPaper-root {
	background-color: var(--panel-semi-dark-background);
	padding: 1.5rem;
}

.MuiSvgIcon-root,
.MuiDialogTitle-root {
	color: #cecfd1;
}

.MuiDialogActions-root {
    padding: 1.5rem!important;
}

.MuiDialogContent-root {
    flex: 1 1 auto !important;
    padding: 8px 24px !important;
    overflow-y: auto !important;
}